import { createSelector } from '@ngrx/store';
import { TelematicLabState } from '..';

export namespace RootSelectors {
  const getRootState = (state: TelematicLabState) => state.root;

  export const getUsersMail = createSelector(
    getRootState,
    (state) => state.email,
  );
}
